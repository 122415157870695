export const environment = {
    production: true,
    environmentName: 'dev',
    port: 4052,
    recaptcha: {
        siteKey: '6LfCEickAAAAAFVEILreANr4tsHb2Fo8PK6kATgr',
        secrectKey: '6LfCEickAAAAAIaKe0PgZdJJgNmoNj15C49NOZ6A',
    },
    localStorageEncryptionSecret: '!@#$&smartawards&@dev!',
    apiUrl: 'https://smartawards-api.devpress.net/v1/api/',
};
