<section class="login-wrapper">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo-onboarding.svg" alt="Smart Awards">
                </a>
            </div>
            <div class="login-content">
                <h1>Multi - Factor Authentication</h1>
                <div *ngIf="!password">
                    <p class="mt-2">Use your mobile authenticator app to generate 6 digit code.</p>
                    <div class="qr-code mt-5" *ngIf="(QRCode != '' && QRCode)">
                        <img [src]="QRCode" width="150" height="150" alt="QR Code">
                    </div>
                </div>
                <div *ngIf="password">
                    <p class="mt-2">To Proceed with Multi-factor Authentication, the user needs to first verify their
                        identity with their password</p>
                </div>
            </div>
            <div class="login-form" *ngIf="password">
                <form #mfaPasswordForm="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input [type]="showPassword ? 'text' : 'password'" name="password" id="password"
                            placeholder="Enter Password" class="form-control"
                            [ngClass]="((passwordSubmitted || password.touched) && password.invalid)?'has-danger':''"
                            [(ngModel)]="userDetails.password" required #password="ngModel"
                            (keypress)="restrictKeyPressService.allowCharacters($event)" />
                        <span [tooltip]="showPassword ? 'Hide Password' : 'Show Password'" toggle="#password-field"
                            class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                                                'bx-bx-hide': !showPassword,
                                                                'bx-show': showPassword
                                                              }" (click)="showPassword = !showPassword"></span>
                        <div *ngIf="(passwordSubmitted || password.touched) && password.invalid" class="has-error">
                            {{messages.ERROR.LOGIN.password}}
                        </div>
                    </div>
                    <div class="login-btn pt-1">
                        <button *ngIf="password" class="btn btn-primary btn-block" type="submit"
                            (click)="callEnableMFA(mfaPasswordForm)">Verify</button>
                    </div>
                </form>
            </div>
            <div class="login-form" *ngIf="!password">
                <form #mfaForm="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="code">Enter Code</label>
                        <input type="text" maxlength="6" name="code" id="name" #code="ngModel" [(ngModel)]="MFA.code"
                            placeholder="Enter OTP Code" class="form-control" required>
                        <div *ngIf="(submitted || code.touched) && code.invalid" class="has-error">
                            {{messages.ERROR.MFA.code}}
                        </div>
                        <div class="login-btn pt-1">
                            <button class="btn btn-primary btn-block" type="submit"
                                (click)="verifyMFA(mfaForm)">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div *ngIf="!password" class="text-center mt-5">Reset MFA?&nbsp;
                            <a href="javascript:;" (click)="callEnableMFA(true)" class="text-link-btn text-primary">Tap here</a>
                        </div> -->
        </div>
    </div>
</section>