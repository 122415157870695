export const APIConstants = {
    Registration: 'oauth/register',
    Login: 'oauth/web/login',
    Social_Login: 'users/socialAccess',
    Logout: 'oauth/logout',
    ForgotPassword: 'oauth/web/forgotPassword',
    ResetPassword: 'oauth/web/resetPassword',
    //
    Get_QR_Code: 'getQRCode',

    // My account
    GetProfileDetails: 'getProfileDetails',
    UpdateProfileDetails: 'updateProfileDetails',
    GetNotificationSettingsList: 'notifications',

    // Change Password
    Change_Password: 'users/changePassword',

    // Operators
    Operator_List: 'users/operatorList',
    Switch_Operator: 'users/switchOperator',

    //Dashboard
    GetDashboardDetails: 'users/homeScreen/',

    //Notification
    GetNotificationList: 'notifications/list',
    NotificationMarkAsRead: 'notifications/markasread',
    DeleteNotification: 'notifications/delete',
    GetNotificationCount: 'userWebNotifications/notificationCount',

    // Courses
    GetCourseList: 'trainingCourses/list/',
    GetCourseById: 'trainingCourses/',
    GetModuleInfo: 'trainingCourseModule/details/',
    GetSubmoduleListByModuleId: 'trainingCourseModule/list/',

    // Submodules
    GetTitleSlide: 'trainingCourseSubmodule/getTitleSlide/',
    GetThreeSixtyUrl: 'trainingCourseSubmodule/getThreeSixtyUrl/',
    GetProductListing: 'trainingCourseSubmodule/productList/',
    GetProductDetail: 'trainingCourseSubmodule/productDetail/',
    SendProductEmail: 'trainingCourseSubmodule/sendEmailProduct',
    SubmitProgress: 'trainingCourseSubmodule/submitProgress',
    GetUploadVideo: 'trainingCourseSubmodule/getUploadVideo/',
    GetQuiz: 'trainingCourseSubmodule/getQuiz/',
    StoreQuizResult: 'trainingCourseSubmodule/storeQuizResult',
    CheckQuizStatus: 'trainingCourseSubmodule/checkQuizStatus/',
    GetPreviousQuizResult: 'trainingCourseSubmodule/getQuizResult/',
    GetAndSendPreviousQuizResultToEmail: 'trainingCourseSubmodule/previousQuizResult',
    //cms
    CmsPages: "cmsPages/",

    //MFA
    EnableMFA: 'mfa/enable-mfa',
    DisableMFA: 'mfa/disable-mfa',
    VerifyMFA: 'mfa/verify-mfa-code',
};
