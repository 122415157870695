<header class="header-section">
    <div class="header-wrap">
        <div class="page-title">
            <h6 class="welcome-text" *ngIf="headerData && headerData?.welcome" [innerHtml]="headerData.welcome"></h6>
            <!-- <a href="javascript:;" class="icon-bx" (click)="openQuitModal(QuitModal)"><em class="bx bx-x"></em></a> -->
            <a class="icon-bx" *ngIf="headerData.prev" (click)="previousNavigation(headerData.prev, headerData.id)"><em
                    class="bx bx-arrow-back"></em></a>
            <h2>{{headerData.title}}</h2>
        </div>
        <div class="header-actions">
            <!-- Filter: BEGIN -->
            <!-- <div class="nav-item">
                <div class="nav-icon">
                    <a href="javascript:;" class="icon-box"><em class="bx bx-info-circle"></em></a>
                </div>
            </div>
            <div class="nav-item">
                <div class="nav-icon">
                    <a href="javascript:;" class="icon-box"><em class="bx bx-share-alt"></em></a>
                </div>
            </div>
            <div class="nav-item">
                <div class="nav-icon-border">
                    <a href="javascript:;" class="icon-box" (click)="openSortModal(SortModal)"><em class="bx bx-sort"></em></a>
                </div>
            </div>
            <div class="nav-item">
                <div class="nav-icon-button">
                    <a href="javascript:;" class="button-box" (click)="openFilterModal(FilterModal)"><em class="bx bx-filter"></em> Filter</a>
                </div>
            </div>
            <div class="nav-item">
                <div class="nav-icon-button">
                    <a href="javascript:;" class="button-box" (click)="openJobFilterModal(JobFilterModal)"><em class="bx bx-filter"></em> Filter</a>
                </div>
            </div>
            <div class="nav-item">
                <div class="nav-icon-button">
                    <a href="javascript:;" class="button-box"><em class="bx bx-save"></em> Save</a>
                </div>
            </div>
            <div class="nav-item">
                <div class="nav-text-button">
                    <a href="javascript:;" class="text-link-btn text-primary">View All Products</a>
                </div>
            </div> -->
            <!-- Search: BEGIN -->
            <!-- <div class="nav-item">
                <div class="nav-search">
                    <a href="javascript:;" class="search-ic" (click)="openORcloseSearch('open')"><em class="bx bx-search"></em></a>
                    <div class="search-input" [ngClass]="{'search-open': isSearchOpen}">
                        <div class="search-box">
                            <div class="search-input-icon"><em class="bx bx-search primary"></em></div>
                            <input class="input" type="text" placeholder="Search..." tabindex="-1">
                            <div class="search-input-close" (click)="openORcloseSearch('close')"><em class="bx bx-x"></em></div>
                            <div class="nav-item">
                                <div class="nav-icon-button">
                                    <a href="javascript:;" class="button-box" (click)="openSearchFilterModal(SearchFilterModal)"><em class="bx bx-filter"></em> Filter</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="nav-item">
                <a [routerLink]="[URLConstants.NOTIFICATION]" class="nav-link nav-link-label p-0" type="button"
                    id="notificationDropdown" aria-controls="dropdown-animated">
                    <em class="bx bx-bell bx-flip-horizontal" [ngClass]="notificationCount ? 'bx-tada' : ''"></em>
                    <span *ngIf="notificationCount!==0" class="badge badge-secondary badge-up"
                        [ngClass]="{'big-digit':notificationCount > 99}">{{notificationCount}}</span>
                </a>
            </div>
            <div class="nav-item">
                <div class="user-dropdown dropdown" dropdown>
                    <a class="dropdown-toggle dropdown-user-link" dropdownToggle type="button" id="userDropdown"
                        aria-controls="dropdown-animated">
                        <img [src]="photo ? photo : '../../../../assets/images/NoProfile.png'" alt="User">
                        <!-- <span class="online-status"></span> -->
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" id="userDropdown" *dropdownMenu role="menu"
                        aria-labelledby="button-animated">
                        <a href="javascript:;" class="dropdown-item" [routerLink]="[URLConstants.MY_ACCOUNT]"><em
                                class='bx bx-user'></em>My Account</a>
                        <a href="javascript:;" class="dropdown-item" (click)="logoutService.logout()"><em
                                class='bx bx-log-out'></em>Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Search Filter MODAL -->
<ng-template #SearchFilterModal>
    <div class="modal-close-box">
        <button type="button" class="modal-close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h5>Filter</h5>
    </div>
    <div class="modal-body">
        <div class="filter-option">
            <ul>
                <li><a href="javascript:;" class="warning active"><em class="bx bx-check-circle"></em> News</a></li>
                <li><a href="javascript:;" class="secondary"><em class="bx bx-check-circle"></em> Resources</a></li>
                <li><a href="javascript:;" class="success"><em class="bx bx-check-circle"></em> Product</a></li>
                <li><a href="javascript:;" class="primary"><em class="bx bx-check-circle"></em> Training</a></li>
            </ul>
        </div>
        <div class="modal-action">
            <a href="javascript:;" class="btn btn-primary btn-size-lg">Done</a>
        </div>
    </div>
</ng-template>
<!-- Search Filter MODAL -->
<!-- Filter MODAL -->
<ng-template #FilterModal>
    <div class="modal-close-box">
        <button type="button" class="modal-close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h5>Filter</h5>
    </div>
    <div class="modal-body">
        <div class="custom-checkbox mb-3">
            <label for="alltime" class="mb-0">
                <input type="checkbox" id="alltime" checked />
                <span></span> All Time </label>
        </div>
        <div class="custom-checkbox mb-3">
            <label for="thismonth" class="mb-0">
                <input type="checkbox" id="thismonth" />
                <span></span> This Month </label>
        </div>
        <div class="custom-checkbox mb-3">
            <label for="lastmonths" class="mb-0">
                <input type="checkbox" id="lastmonths" />
                <span></span> Last 6 Months </label>
        </div>
        <div class="custom-checkbox mb-3">
            <label for="lastyear" class="mb-0">
                <input type="checkbox" id="lastyear" />
                <span></span> Last Year </label>
        </div>
        <div class="modal-action">
            <a href="javascript:;" class="btn btn-primary btn-size-lg">Filter</a>
        </div>
    </div>
</ng-template>
<!-- Filter MODAL -->
<!-- Sorting MODAL -->
<ng-template #SortModal>
    <div class="modal-close-box">
        <button type="button" class="modal-close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h5>Sort</h5>
    </div>
    <div class="modal-body">
        <div class="custom-checkbox mb-3">
            <label for="atoz" class="mb-0">
                <input type="checkbox" id="atoz" checked />
                <span></span> A-Z </label>
        </div>
        <div class="custom-checkbox mb-3">
            <label for="ztoa" class="mb-0">
                <input type="checkbox" id="ztoa" />
                <span></span> Z-A </label>
        </div>
        <div class="custom-checkbox mb-3">
            <label for="recentlyadded" class="mb-0">
                <input type="checkbox" id="recentlyadded" />
                <span></span> Recently Added </label>
        </div>
        <div class="custom-checkbox mb-3">
            <label for="oldest" class="mb-0">
                <input type="checkbox" id="oldest" />
                <span></span> Oldest </label>
        </div>
        <div class="modal-action">
            <a href="javascript:;" class="btn btn-primary btn-size-lg">Filter</a>
        </div>
    </div>
</ng-template>
<!-- Sorting MODAL -->
<!-- Quiz Quit MODAL -->
<ng-template #QuitModal>
    <div class="modal-close-box">
        <button type="button" class="modal-close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h5>Quit</h5>
    </div>
    <div class="modal-body">
        <p>Do you really want to quit the Quiz?</p>
        <div class="modal-action flex-action">
            <a href="javascript:;" class="btn btn-gray">Cancel</a>
            <a href="javascript:;" class="btn btn-primary">Quit</a>
        </div>
    </div>
</ng-template>
<!-- Quiz Quit MODAL -->
<!-- Job Filter MODAL -->
<ng-template #JobFilterModal>
    <div class="modal-close-box">
        <button type="button" class="modal-close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header">
        <h5>Filter</h5>
    </div>
    <div class="modal-body filter-modal-inner">
        <a href="javascript:;" class="filter-btn">Reset Filter</a>
        <div class="jobs-options">
            <h5>Job Topic</h5>
            <div class="custom-checkbox mb-3">
                <label for="option01" class="mb-0">
                    <input type="checkbox" id="option01" checked />
                    <span></span> Option 01 </label>
            </div>
            <div class="custom-checkbox mb-3">
                <label for="option02" class="mb-0">
                    <input type="checkbox" id="option02" />
                    <span></span> Option 02 </label>
            </div>
            <div class="custom-checkbox mb-3">
                <label for="option03" class="mb-0">
                    <input type="checkbox" id="option03" />
                    <span></span> Option 03 </label>
            </div>
        </div>
        <div class="jobs-options">
            <h5>Job Status</h5>
            <div class="custom-checkbox mb-3">
                <label for="option04" class="mb-0">
                    <input type="checkbox" id="option04" checked />
                    <span></span> Option 01 </label>
            </div>
            <div class="custom-checkbox mb-3">
                <label for="option05" class="mb-0">
                    <input type="checkbox" id="option05" />
                    <span></span> Option 02 </label>
            </div>
            <div class="custom-checkbox mb-3">
                <label for="option06" class="mb-0">
                    <input type="checkbox" id="option06" />
                    <span></span> Option 03 </label>
            </div>
        </div>
        <div class="modal-action">
            <a href="javascript:;" class="btn btn-primary btn-size-lg">Filter</a>
        </div>
    </div>
</ng-template>
<!-- Job Filter MODAL -->