export const messages = {
    ERROR: {
        REQUIRED: {
            username: 'Enter Username',
            firstname: 'Enter First Name',
            lastname: 'Enter Last Name',
            emailId: 'Enter Email',
            mobile: 'Enter Mobile',
            oldPassword: 'Enter Old Password',
            newPassword: 'Enter Password',
            confirmPassword: 'Enter Confirm Password',
            name: 'Enter Name',
            quizMarked:'To review the quiz, all answer must be marked.'
        },
        LOGIN: {
            emailId: 'Enter Email',
            password: 'Enter Password',
        },
        MFA: {
            code: 'Enter Code',
        },
        SIGNUP: {
            name: 'Enter Name',
        },
        PATTERN: {
            emailId: 'Enter Valid Email Address',
            newPassword: 'Password must have atleast 8 characters and should contain atleast 4 among uppercase, lowercase, digit, special character',
            confirmPassword: 'Password does not match',
        },
        MINLENGTH: {
            mobile: 'Mobile must have atleast 10 digits',
        },
        CUSTOM: {
            quizTimeout:'The test has been submitted because you are out of time',
            pdf:'PDF of your results is being generated. Please try again later',
        },
    },
};
