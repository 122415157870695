import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
/* Authguards */
import { CanLoginActivate, CanAuthActivate } from './common/services/TS-files/auth.gaurd';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [CanLoginActivate],
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule)
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesModule)
  },
  {
    path: "maintenance-mode",
    component: MaintenanceComponent,
    canActivate: [CanAuthActivate],
    pathMatch: "full",
  },
  { path: '**', redirectTo: '/' }

];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [CanLoginActivate, CanAuthActivate]
})
export class AppRoutingModule { }
