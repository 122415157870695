import { Component, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

//Services
import { StorageService } from '../../../common/services/service/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent implements DoCheck {

  URLConstants = URLConstants;
  operatorName: string;
  operatorLogo: string;
  localStorageConstants = localStorageConstants;
  constructor(private storageService: StorageService, public router: Router) { }

  ngDoCheck() {
    this.operatorName = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.OperatorName) : this.storageService.getSessionToken(this.localStorageConstants.OperatorName);
    this.operatorLogo = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.OperatorLogo) : this.storageService.getSessionToken(this.localStorageConstants.OperatorLogo);
  }

}





