<aside class="sidebar">
    <div class="sidebar-inner-wrapper">
        <div class="logo-box" style="background-image: url(../../../../assets/images/sidebar-bg.svg);">
            <a href="javascript:;" [routerLink]="[URLConstants.DASHBOARD]" class="logo">
                <div class="d-flex align-items-center short-text-wrap">
                    <div class="short-text">
                        <img [src]="operatorLogo ? operatorLogo : 'assets/images/NoProfile.png'" [alt]="operatorName">
                    </div>
                    <h2 class="text-black mb-0 ml-2">{{operatorName}}</h2>
                </div>
            </a>
        </div>
        <div class="sidebar-menu">
            <ul class="sidebar-menu-list">
                <li class="menu-list-item">
                    <a href="javascript:;" [routerLink]="[URLConstants.DASHBOARD]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.DASHBOARD)}">
                        <em class="icon-home"></em>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a href="javascript:;" [routerLink]="[URLConstants.COURSES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.COURSES) || router.url.includes(URLConstants.VIEW_COURSE_DETAIL) || router.url.includes(URLConstants.VIEW_MODULE_DETAIL)}">
                        <em class="icon-training-courses"></em>
                        <span class="menu-title">Assessments</span>
                        <!-- <span class="menu-title">Training Courses</span> -->
                    </a>
                </li>
                <!-- <li class="menu-list-item">
                    <a href="javascript:;" class="menu-link">
                        <em class="icon-products"></em>
                        <span class="menu-title">Products</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item">
                    <a href="javascript:;" class="menu-link">
                        <em class="icon-featured-products"></em>
                        <span class="menu-title">Featured Products</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item">
                    <a href="javascript:;" [routerLink]="[URLConstants.MY_LIST]" class="menu-link">
                        <em class="icon-favourites"></em>
                        <span class="menu-title">My List</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a href="javascript:;" [routerLink]="[URLConstants.JOBS_LIST]" class="menu-link">
                        <em class="icon-jobs"></em>
                        <span class="menu-title">Jobs</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item">
                    <a href="javascript:;" class="menu-link">
                        <em class="icon-news"></em>
                        <span class="menu-title">News</span>
                    </a>
                </li> -->
                <li class="menu-list-item">
                    <a href="javascript:;" [routerLink]="[URLConstants.MY_ACCOUNT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MY_ACCOUNT) || router.url.includes(URLConstants.MANAGE_PASSWORD) || router.url.includes(URLConstants.ACCREDATION) || router.url.includes(URLConstants.NOTIFICATION_SETTINGS)}">
                        <em class="icon-profile"></em>
                        <span class="menu-title">Profile</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="sidebar-contact-icon">
            <a class="btn btn-primary" href="javascript:;" [routerLink]="[URLConstants.CONTACT]"><em
                    class="bx bx-phone-call"></em></a>
            <a class="btn btn-primary" href="https://www.smartawards.co.uk/contact-us/" target="_blank"><em class="bx bx-help-circle"></em></a>
            <!-- <a class="btn btn-primary" href="javascript:;" [routerLink]="[URLConstants.HELP]"><em class="bx bx-help-circle"></em></a> -->
        </div>
    </div>
</aside>