import { environment } from "src/environments/environment";

export const localStorageConstants = {
    IsRememberMe: 'isRememberMe',
    KeepMeSignIn: 'keepMeSignIn',
    AccessToken: 'accessToken',
    Name: 'name',
    Email: 'email',
    Pass: 'Pass',
    Photo: 'photo',
    Notification: 'notification',
    OperatorId: 'operatorId',
    OperatorName: 'operatorName',
    OperatorLogo: 'operatorLogo',

    //MFA
    EnableMFA: 'enableMFA',
    QRCode: 'qr_code',
    MFAUserId: 'mfa_user_id',
    ID: 'id',
    // Submodule keys
    SubmoduleInfo: 'subModule_info',
    QuizResultPDFurl: 'quizResultPDFurl'
}
export const SECURE_LS = {
    ENCODING_TYPE: 'aes',
    STORAGE_KEY: '__secure__sb__ls__',
    ENCRYPTION_SECRET: environment.localStorageEncryptionSecret,
};
